import { Injectable } from '@angular/core';
import { HttpBackendService } from '@memberspot/frontend/shared/data-access/common';
import {
  AllOfferProperties,
  Offer,
  OfferCreateProperties,
  OfferManageEndpoints,
  OfferMessageBodyProperties,
  OfferOrderProperties,
  OfferProperties,
  OfferSelfSignupProperties,
  OfferZapierProperties,
} from '@memberspot/shared/model/offer';
import { tap } from 'rxjs';

import { OffersStore } from './offers.store';

@Injectable({ providedIn: 'root' })
export class OffersService {
  constructor(
    private store: OffersStore,
    private http: HttpBackendService,
  ) {}

  sync(schoolId: string) {
    this.store.reset();
    this.store.setLoading(true);

    return this.http
      .generic<Offer[]>(OfferManageEndpoints.LIST_OFFERS(schoolId))
      .pipe(
        tap((offers) => {
          this.store.set(offers);
          this.store.setLoading(false);
        }),
      );
  }

  resetStore() {
    this.store.reset();
  }

  addOffer(properties: OfferCreateProperties) {
    const { schoolId, name } = properties;

    return this.http
      .generic<Offer>(
        OfferManageEndpoints.CREATE_OFFER(schoolId, {
          schoolId,
          name,
        }),
      )
      .pipe(
        tap((offer) => {
          this.store.upsert(offer.id, offer);
        }),
      );
  }

  deleteOffer(schoolId: string, offerId: string) {
    return this.http
      .generic(OfferManageEndpoints.DELETE_OFFER(schoolId, offerId))
      .pipe(
        tap(() => {
          this.store.remove(offerId);
        }),
      );
  }

  updateOffer(schoolId: string, offerId: string, properties: OfferProperties) {
    return this.http
      .generic<Offer>(
        OfferManageEndpoints.UPDATE_OFFER_PROPERTIES(
          schoolId,
          offerId,
          properties,
        ),
      )
      .pipe(
        tap((offer) => {
          this.store.upsert(offer.id, offer);
        }),
      );
  }

  updateAllOfferProperties(
    schoolId: string,
    id: string,
    properties: AllOfferProperties,
  ) {
    return this.http
      .generic<Offer>(
        OfferManageEndpoints.UPDATE_ALL_OFFER_PROPERTIES(
          schoolId,
          id,
          properties,
        ),
      )
      .pipe(
        tap((offer) => {
          this.store.upsert(offer.id, offer);
        }),
      );
  }

  updateMessage(
    schoolId: string,
    offerId: string,
    properties: OfferMessageBodyProperties,
  ) {
    return this.http
      .generic<Offer>(
        OfferManageEndpoints.UPDATE_MESSAGE(schoolId, offerId, properties),
      )
      .pipe(
        tap((offer) => {
          this.store.upsert(offer.id, offer);
        }),
      );
  }

  updateSelfSignup(
    schoolId: string,
    offerId: string,
    properties: OfferSelfSignupProperties,
  ) {
    return this.http
      .generic<Offer>(
        OfferManageEndpoints.UPDATE_SELF_SIGNUP(schoolId, offerId, properties),
      )
      .pipe(
        tap((offer) => {
          this.store.upsert(offer.id, offer);
        }),
      );
  }

  updateZapier(
    schoolId: string,
    offerId: string,
    properties: OfferZapierProperties,
  ) {
    return this.http
      .generic<Offer>(
        OfferManageEndpoints.UPDATE_ZAPIER(schoolId, offerId, properties),
      )
      .pipe(
        tap((offer) => {
          this.store.upsert(offer.id, offer);
        }),
      );
  }

  updateSorting(schoolId: string, offers: Offer[]) {
    const properties: OfferOrderProperties[] = offers
      .map((offer) => ({
        id: offer.id,
        priority: offer.priority || 1000,
      }))
      .map((val, index) => ({
        id: val.id,
        priority: index,
      }));

    return this.http
      .generic<Offer[]>(
        OfferManageEndpoints.UPDATE_SORT_ORDER(schoolId, properties),
      )
      .pipe(
        tap((sortedOffers) => {
          this.store.set(sortedOffers);
          this.store.setLoading(false);
        }),
      );
  }
}
