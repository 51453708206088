import { BackendMethod } from '@memberspot/shared/model/backend';

import {
  AllOfferProperties,
  OfferCreateProperties,
  OfferMessageBodyProperties,
  OfferOrderProperties,
  OfferProperties,
  OfferSelfSignupProperties,
  OfferZapierProperties,
} from './offer.model';

export const OfferManageEndpoints = {
  GET_OFFER: (schoolId: string, offerId: string) => ({
    url: `/offer/manage/${schoolId}/${offerId}`,
    method: BackendMethod.GET,
  }),
  LIST_OFFERS: (schoolId: string) => ({
    url: `/offer/manage/list/${schoolId}`,
    method: BackendMethod.GET,
  }),
  CREATE_OFFER: (schoolId: string, data: OfferCreateProperties) => ({
    url: `/offer/manage/create/${schoolId}`,
    data,
    method: BackendMethod.POST,
  }),
  DELETE_OFFER: (schoolId: string, offerId: string) => ({
    url: `/offer/manage/${schoolId}/${offerId}`,
    method: BackendMethod.DELETE,
  }),
  UPDATE_SELF_SIGNUP: (
    schoolId: string,
    offerId: string,
    data: OfferSelfSignupProperties,
  ) => ({
    url: `/offer/manage/self-signup/${schoolId}/${offerId}`,
    data,
    method: BackendMethod.PUT,
  }),
  UPDATE_ZAPIER: (
    schoolId: string,
    offerId: string,
    data: OfferZapierProperties,
  ) => ({
    url: `/offer/manage/zapier/${schoolId}/${offerId}`,
    data,
    method: BackendMethod.PUT,
  }),
  UPDATE_MESSAGE: (
    schoolId: string,
    offerId: string,
    data: OfferMessageBodyProperties,
  ) => ({
    url: `/offer/manage/message/${schoolId}/${offerId}`,
    data,
    method: BackendMethod.PUT,
  }),
  UPDATE_OFFER_PROPERTIES: (
    schoolId: string,
    offerId: string,
    data: OfferProperties,
  ) => ({
    url: `/offer/manage/${schoolId}/${offerId}`,
    data,
    method: BackendMethod.PUT,
  }),
  UPDATE_ALL_OFFER_PROPERTIES: (
    schoolId: string,
    id: string,
    data: AllOfferProperties,
  ) => ({
    url: `/offer/manage/properties/${schoolId}/${id}`,
    data,
    method: BackendMethod.PUT,
  }),
  UPDATE_SORT_ORDER: (
    schoolId: string,
    priorities: OfferOrderProperties[],
  ) => ({
    url: `/offer/manage/order/${schoolId}`,
    data: { priorities },
    method: BackendMethod.PUT,
  }),
  GET_OFFER_NON_MEMBERS: (
    schoolId: string,
    id: string,
    searchQuery?: string,
    page: number = 0,
  ) => ({
    url: createMembersPagingUrl(
      `/offer/manage/list/users-without-offer/${schoolId}/${id}`,
      searchQuery,
      page,
    ),
    method: BackendMethod.GET,
  }),
};

export const createMembersPagingUrl = (
  baseUrl: string,
  searchQuery?: string,
  page?: number,
) => {
  const params = [];

  if (searchQuery) {
    params.push(`query=${searchQuery}`);
  }

  if (page) {
    params.push(`page=${page}`);
  }

  return params.length ? `${baseUrl}?${params.join('&')}` : baseUrl;
};
